import { useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

function ScrollToTopWrapper() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
}

export default ScrollToTopWrapper;
