import { useEffect } from "react";
import { withLocalize } from "react-localize-redux";
import "./quickClaimProgress.scss";
import { useHistory, useLocation } from "react-router-dom";
import { ClaimingProcessBlock } from "wallet-elementkit/dist/components";
import { quickClaim } from "wallet-elementkit/dist/services/backend/Vcl";
import translations from "../../translations/claimFlow.json";
import Notification from "wallet-elementkit/dist/elementKit/notification/Notification";
function QuickClaimProgress(props) {
	props.addTranslation(translations);
	const location = useLocation();
	const history = useHistory();
	const queryParams = new URLSearchParams(location.search);
	const type = queryParams.get("type");
	const value = queryParams.get("value");
	const credNo = queryParams.get("credNo");
	const orgName = queryParams.get("orgName");

	async function claimCredential() {
		if (!type || !value || !credNo || !orgName) {
			// handle error
			Notification({
				type: "error",
				text: "Invalid query params",
			});
			return;
		}
		// Issuing
		const response = await quickClaim({
			type: type,
			value: value,
		});

		if (response.success) {
			Notification({
				type: "success",
				text: props.translate("credentialClaimed").toString(),
			});
			history.push({
				pathname: `/${props.activeLanguage?.code ?? "en"}/verify/${
					response.data[0].velocityCredential.credentialNo
				}`,
			});
		} else {
			Notification({
				type: "error",
				text: props.translate("errorClaimingCredential").toString(),
			});
			history.push({
				pathname: `/${props.activeLanguage?.code ?? "en"}/verify/${credNo}`,
			});
		}
	}
	useEffect(() => {
		claimCredential();
	}, []);

	return (
		<ClaimingProcessBlock
			claimOrganizationTitle={orgName || "No Organization"}
			{...props}
		/>
	);
}

export default withLocalize(QuickClaimProgress);
