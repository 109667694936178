import { useState, useEffect, useLayoutEffect } from "react";
import ReactCodeInput from "react-code-input";
import "../../styles/verifyCode/verifyCode.scss";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import BaseButton from "wallet-elementkit/dist/elementKit/baseButton/BaseButton";

import FixedInfoFooter from "../../components/fixedInfoFooter/FixedInfoFooter";
import Countdown from "react-countdown";
import {
	resendLoginVerificationCode,
	verifyLoginMfa,
} from "../../services/verifiedBackendService/user";
import Notification from "../../components/notification/Notification";
import Recaptcha from "../../elementKit/recaptcha/Recaptcha";
import { Translate, withLocalize } from "react-localize-redux";
import translations from "../../translations/signup.json";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectUser, userActions } from "../../store/features/user";
import { OptimizedMixPanel } from "wallet-elementkit/dist/services";
import mixpanel from "mixpanel-browser";
import {
	resendEmailVerificationCode,
	verifyEmail,
} from "../../services/verifiedBackendService/emailVerification";
const VerifyCode = (props) => {
	useLayoutEffect(() => {
		props.addTranslation(translations);
	}, []);

	const userMeta = useAppSelector(selectUser);
	const dispatch = useAppDispatch();
	const history = useHistory();
	const location = useLocation();
	const optimizedMixpanel = new OptimizedMixPanel(mixpanel);

	const [verificationCode, setVerificationCode] = useState("");
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [countDownDate, setCountDownDate] = useState(
		new Date().getTime() + 30000
	);
	const [shouldRedirectOptions, setShouldRedirectOptions] = useState();
	const [status, setStatus] = useState("login");

	useEffect(() => {
		const search = location.search;
		const email =
			new URLSearchParams(search).get("email") || userMeta.user.email;

		setEmail(email || "");
	}, [location.search]);

	useEffect(() => {
		// Setting Up the status from location state whether it's login or signup
		const stateData = location.state;
		if (stateData?.status) {
			setStatus(stateData.status);
		}
		if (stateData?.shouldRedirectOptions) {
			setShouldRedirectOptions(stateData.shouldRedirectOptions);
		}
	}, [location.state]);

	const codeInputStyles = {
		inputStyleInvalid: {
			width: "45px",
			borderRadius: "0px",
			fontSize: "35px",
			lineHeight: "50px",
			height: "60px",
			padding: "5px 10px",
			backgroundColor: "none",
			color: "#e43a48",
			border: "none",
			borderBottom: "3px solid #e43a48",
		},
	};
	const [showRecaptcha, setShowRecaptcha] = useState(false);
	const [recaptcha, setRecaptcha] = useState({
		token: null,
		valid: false,
	});

	// every time recapcha changes, check if it's valid then send verification code
	useEffect(() => {
		if (recaptcha.valid) {
			resendVerificationCode({
				token: recaptcha.token,
				valid: recaptcha.valid,
			});
		}
	}, [recaptcha]);

	const verifyCodeSignup = async () => {
		setError(false);
		if (verificationCode.length < 6) {
			setError(true);
			return;
		}
		setLoading(true);

		const userData = await verifyEmail(verificationCode);
		if (userData !== null && userData.success) {
			optimizedMixpanel.track("Verify Code", {
				authType: "signup",
				userId: userData.user.id,
				from: shouldRedirectOptions?.actionType,
				isLogged: userMeta.logged,
			});
			dispatch(userActions.setUser(userData.user));
			dispatch(userActions.setUserWalletData(userData.userWalletData));
			dispatch(userActions.setUserEmails(userData.userEmails));
			dispatch(userActions.setLogged(true));
			dispatch(userActions.setAllUserWallets(userData.allUserWallets));
			dispatch(userActions.setIsMailConfirmed(true));
			mixpanel.identify(userData.user.id.toString());
			mixpanel.people.set({
				$email: userData.user.email,
				$name: userData.user.name,
				username: userData.user.username,
				emails: userData.userEmails.map((email) => email.value),
			});
			setLoading(false);
			history.push({
				pathname:
					shouldRedirectOptions != null
						? shouldRedirectOptions.shouldRedirectTo
						: `/${
								props.activeLanguage ? props.activeLanguage.code : "en"
						  }/profile/${userData.user.username}`,
				search: shouldRedirectOptions?.redirectSearch ?? "",
				state: shouldRedirectOptions != null && {
					fromVerification: true,
					actionType: shouldRedirectOptions.actionType,
				},
			});
		} else {
			if (userData && userData.error) {
				Notification({
					type: "error",
					text: props.translate(userData.error).toString(),
				});
			} else {
				Notification({
					type: "error",
					text: props.translate("error").toString(),
				});
			}
			setLoading(false);
		}
	};

	const verifyCodeLogin = async () => {
		setError(false);
		if (verificationCode.length < 6) {
			setError(true);
			return;
		}

		setLoading(true);

		const userData = await verifyLoginMfa(verificationCode, userMeta.userId);
		if (userData !== null && userData.success) {
			optimizedMixpanel.track("Verify Code", {
				authType: "login",
				userId: userData.user.id,
				from: shouldRedirectOptions?.actionType,
			});
			dispatch(userActions.setUser(userData.user));
			dispatch(userActions.setUserWalletData(userData.userWalletData));
			dispatch(userActions.setLogged(true));
			dispatch(userActions.setAllUserWallets(userData.allUserWallets));
			dispatch(userActions.setUserEmails(userData.userEmails));
			dispatch(userActions.setIsMailConfirmed(true));
			mixpanel.identify(userData.user.id.toString());
			mixpanel.people.set({
				$email: userData.user.email,
				$name: userData.user.name,
				username: userData.user.username,
				emails: userData.userEmails.map((email) => email.value),
			});
			history.push({
				pathname:
					shouldRedirectOptions != null
						? shouldRedirectOptions.shouldRedirectTo
						: `/${
								props.activeLanguage ? props.activeLanguage.code : "en"
						  }/profile/${userData.user.username}`,
				search: shouldRedirectOptions?.redirectSearch ?? "",
				state: shouldRedirectOptions != null && {
					fromVerification: true,
					actionType: shouldRedirectOptions.actionType,
				},
			});
		} else {
			if (userData) {
				Notification({
					type: "error",
					text: props.translate(userData.error).toString(),
				});
			} else {
				Notification({
					type: "error",
					text: props.translate("error").toString(),
				});
			}
			setLoading(false);
		}
	};

	const resendVerificationCode = async (recaptchaParam) => {
		setError(false);

		if (!recaptchaParam.valid) {
			Notification({
				type: "error",
				text: props.translate("recaptchaError"),
			});
			return;
		}
		const res =
			status === "login"
				? await resendLoginVerificationCode(email, recaptchaParam.token || "")
				: await resendEmailVerificationCode(email, recaptchaParam.token || "");
		if (res && res.success) {
			optimizedMixpanel.track("Resend Verification Code", {
				authType: status,
				userId: userMeta.userId,
				email: email,
			});
			Notification({
				type: "success",
				text: props.translate("codeSent"),
			});
			setShowRecaptcha(false);
		} else {
			Notification({
				type: "error",
				text: props.translate(res?.error || "error"),
			});
		}
		setCountDownDate(new Date().getTime() + 30000);
	};

	return (
		<div id="verify-code-main">
			<div className="verify-code-container">
				<span className="verify-title">
					<Translate id="enterTheOneTimePassword" />
				</span>
				<span
					className="verify-desc"
					style={{
						marginTop: "18px",
						marginBottom: "60px",
					}}
				>
					<Translate id="verifyCodeHelperText" />
				</span>
				<ReactCodeInput
					className="verification-code-input"
					type="number"
					fields={6}
					name="code-input"
					inputMode="numeric"
					value={verificationCode}
					onChange={(val) => {
						setError(false);
						setVerificationCode(val);
					}}
					isValid={!error}
					{...codeInputStyles}
				/>
				{showRecaptcha && (
					<Recaptcha
						onChange={(recaptcha) => setRecaptcha(recaptcha)}
						styles={{ marginBottom: "20px" }}
					/>
				)}
				<span
					className="have-account-login-text"
					style={{
						marginBottom: "80px",
					}}
				>
					<Translate id="didntReceiveTheCode" />{" "}
					<Countdown
						date={countDownDate}
						key={countDownDate}
						renderer={({ hours, minutes, seconds, completed }) => {
							if (completed) {
								return (
									<span
										onClick={() => {
											setShowRecaptcha(true);
										}}
										className="resend-link"
									>
										{props.translate("resend").toString()}
									</span>
								);
							}
							return (
								<span
									onClick={() => {
										Notification({
											type: "error",
											text: `Resend in  ${seconds} seconds`,
										});
									}}
									className="resend-link"
								>
									{`Resend in  ${seconds} seconds`}
								</span>
							);
						}}
					/>
				</span>
				<BaseButton
					className="send-verif-code-btn"
					type="primary"
					size="large"
					width={337}
					loading={loading}
					onClickAction={() => {
						status === "login" ? verifyCodeLogin() : verifyCodeSignup();
					}}
					height={39}
				>
					<span>
						<Translate id="verify" />
					</span>
				</BaseButton>
			</div>
			<FixedInfoFooter
				title={props.translate("needToVerifyItsYou").toString()}
				desc={props.translate("needToVerifyHelperText".toString())}
			/>
		</div>
	);
};
export default withLocalize(VerifyCode);
