import { withLocalize } from "react-localize-redux";
import "./suspenseLoader.scss";
import React, { Component } from "react";

class SuspenseLoader extends Component {
	constructor() {
		super();
		this.state = {};
	}

	render() {
		return <div id="suspense-loader" />;
	}
}
export default withLocalize(SuspenseLoader);
