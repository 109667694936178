import "../../styles/components/baseLink.scss";

function Link(props) {
	const triggerOnCLick = () => {
		if (props.onClickAction) {
			props.onClickAction();
		}
	};

	return (
		<a
			className={`base-link ${props.className ? props.className : ""}`}
			href={props.type === "mail" ? `mailto:${props.href}` : props.href}
			title={props.title}
			onClick={triggerOnCLick}
			target={props.openInNewTab ? "_blank" : undefined}
			rel={
				props.rel != undefined
					? props.rel
					: props.openInNewTab
					? "noreferrer"
					: undefined
			}
			style={{ ...props.styles }}
		>
			<span className="text">{props.text}</span>
		</a>
	);
}

export default Link;
