import "bootstrap/dist/css/bootstrap.css";
import { useRef, useState } from "react";
import VisibilityOffIcon from "../../assets/images/icons/visibility-off-icon.svg";
import VisibilityOnIcon from "../../assets/images/icons/visibility-on-icon.svg";
import "./baseInput.scss";
import SuccessInputIcon from "../../assets/images/icons/success-input-icon.svg";
import ErrorInputIcon from "../../assets/images/icons/error-input-icon.svg";
import InfoInputIcon from "../../assets/images/icons/info-input-icon.svg";
import SearchInputIcon from "../../assets/images/icons/search-input-icon.svg";
import { InputSuffixIcon } from "../../constants/Enums";

function Input(props) {
	// input ref variable
	const inputRef = useRef(null);
	const wrapperRef = useRef(null);

	const [inputType, setInputType] = useState(props.type);

	function getPrefixSuffixIconByEnum(iconEnum: InputSuffixIcon) {
		switch (iconEnum) {
			case InputSuffixIcon.success:
				return SuccessInputIcon;
			case InputSuffixIcon.error:
				return ErrorInputIcon;
			case InputSuffixIcon.info:
				return InfoInputIcon;
			case InputSuffixIcon.search:
				return SearchInputIcon;
			default:
				return SuccessInputIcon;
		}
	}

	function isCharacterErrorActive(): boolean {
		if (props.isErrorActive) {
			return true;
		}
		if (
			props.characterCounterOptions &&
			props.value &&
			props.value.toString().length >
				props.characterCounterOptions.maxCharachters
		) {
			return true;
		} else {
			return false;
		}
	}

	return (
		<div
			className="base-input-general flex-column ai-fs"
			ref={wrapperRef}
			style={{
				...props.wrapperStyles,
			}}
		>
			<div
				className="flex-row visibility-row"
				style={{
					position:
						props.visibilityToggleEnabled ||
						props.suffixIcon ||
						props.prefixIcon
							? "relative"
							: "static",
				}}
			>
				<input
					type={inputType}
					ref={inputRef}
					value={props.value}
					maxLength={props.characterCounterOptions?.maxCharachters}
					onChange={(e) => {
						if (props.onChangeAction) {
							props.onChangeAction(e.target.value);
						}
					}}
					onKeyPress={props.onKeyPressAction}
					onFocus={props.onFocusAction}
					onBlur={props.onBlurAction}
					placeholder={props.placeholder}
					className={`base-input flex-row  ${
						isCharacterErrorActive() ? "error" : ""
					} ${props.successStateOptions ? "success" : ""} ${
						props.className ? props.className : ""
					}`}
					style={{
						minWidth: `${props.width}px`,
						maxWidth: `${props.width}px`,
						minHeight: `${props.height}px`,
						maxHeight: `${props.height}px`,
						...props.styles,
					}}
				/>
				{props.visibilityToggleEnabled && (
					<img
						className="visibility-button"
						src={
							inputType === "password" ? VisibilityOnIcon : VisibilityOffIcon
						}
						style={{
							right: props.suffixIcon ? "40px" : "15px",
							...props.visibilityButtonStyles,
						}}
						onClick={() => {
							setInputType(inputType === "password" ? "text" : "password");
						}}
						alt="visibility"
					/>
				)}
				{props.suffixIcon && (
					<img
						className="suffix-icon"
						src={getPrefixSuffixIconByEnum(props.suffixIcon)}
						height={15}
						width={15}
						alt="suffix-icon"
					/>
				)}
				{props.prefixIcon && (
					<img
						className="prefix-icon"
						src={getPrefixSuffixIconByEnum(props.prefixIcon)}
						height={15}
						width={15}
						alt="prefix-icon"
					/>
				)}
				{props.characterCounterOptions && (
					<span
						className="character-counter"
						style={{
							height: props.characterCounterOptions ? undefined : `17px`,
							maxWidth: `${props.width}px`,
							...props.characterCounterOptions.characterCounterStyles,
							color:
								props.value &&
								props.value.toString().length >
									props.characterCounterOptions.maxCharachters
									? "red"
									: undefined,
						}}
					>
						{props.value?.toString().length}/
						{props.characterCounterOptions.maxCharachters}
					</span>
				)}
			</div>
			{props.isErrorActive && (
				<span
					className="error-text"
					style={{
						height: props.isErrorActive ? undefined : `17px`,
						maxWidth: `${props.width}px`,
						...props.errorTextStyles,
					}}
				>
					{props.errorText}
				</span>
			)}
			{!props.isErrorActive && props.successStateOptions && (
				<span
					className="success-text"
					style={{
						height: props.successStateOptions ? undefined : `17px`,
						maxWidth: `${props.width}px`,
					}}
				>
					{props.successStateOptions.successText}
				</span>
			)}
			{!props.isErrorActive &&
				props.successStateOptions == null &&
				props.helperText && (
					<span
						className="helper-text"
						style={{
							height: props.helperText != null ? undefined : `17px`,
							maxWidth: `${props.width}px`,
						}}
					>
						{props.helperText}
					</span>
				)}
		</div>
	);
}

export default Input;
