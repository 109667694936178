const { StorageKeys } = require("../constants/Enums");

module.exports.storeItem = (key, val) => {
	try {
		let item = val;
		if (val.constructor === JSON.constructor) {
			item = JSON.stringify(val);
		}
		window.localStorage.setItem(key, item);
	} catch (error) {
		console.log(error);
	}
};

module.exports.getItem = (key) => {
	let item = window.localStorage.getItem(key);
	try {
		return JSON.parse(item);
	} catch (error) {
		console.log(error);
	}
};

module.exports.setToken = (token) => {
	localStorage.setItem(StorageKeys.user_token, token);
};

module.exports.getToken = () => localStorage.getItem(StorageKeys.user_token);

module.exports.clearExceptConfig = () => {
	// clear local storage except "masterConfig"
	let masterConfig = this.getItem("masterConfig");
	localStorage.clear();
	this.storeItem("masterConfig", masterConfig);
};

module.exports.removeItem = (key) => {
	try {
		window.localStorage.removeItem(key);
	} catch (error) {
		console.log(error);
	}
};
