export function getMixpanelToken() {
	// if url has localhost, return the test token, otherwise return the production token
	if (
		window.location.origin.match(/localhost/g) ||
		window.location.origin.match(/beta-verified.sertifier.com/g)
	) {
		// return test token
		return "f5fc7ae3ec6e86ab9b616aaf526f9866";
	}
	// return production token
	return "f39c7131592292c99da93beb1ade5a65";
}
