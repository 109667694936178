import { createSlice } from "@reduxjs/toolkit";

const getInitialState = () => ({
	logged: false,
	user: {
		id: 0,
		name: "",
		profile_url: null,
		username: "",
		email: "",
		phone: "",
		password: "",
		invisible: false,
		profile_info: "",
		role: "",
		activation: "",
		origin: 0,
		isSubscriber: false,
		isOnboardingDone: false,
		isCurator: false,
		appOrigin: 0,
		createdAt: "",
		updatedAt: "",
	},
	userWalletData: {
		id: 0,
		profileBannerImageUrl: null,
		isWeeklyNewsletterSubscribed: false,
		userId: 0,
		createdAt: "",
		updatedAt: "",
	},
	allUserWallets: [],
	userId: null,
	isMailConfirmed: false,
	credentials: null,
	issuer: null,
	userEmails: [],
});

const initialState = getInitialState();

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
		},
		setUserWalletData: (state, action) => {
			state.userWalletData = action.payload;
		},
		setLogged: (state, action) => {
			state.logged = action.payload;
		},
		setIsMailConfirmed: (state, action) => {
			state.isMailConfirmed = action.payload;
		},
		logout: (state) => {
			Object.assign(state, getInitialState());
		},
		setUserId: (state, action) => {
			state.userId = action.payload;
		},
		setCredentials: (state, action) => {
			state.credentials = action.payload;
		},
		setIssuer: (state, action) => {
			state.issuer = action.payload;
		},
		setUserEmails: (state, action) => {
			state.userEmails = action.payload;
		},
		setAllUserWallets: (state, action) => {
			state.allUserWallets = action.payload;
		},
	},
});

export const userActions = userSlice.actions;

export const selectUser = (state) => state.user;

export default userSlice.reducer;
