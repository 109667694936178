import { toast } from "react-toastify";

import "../../styles/components/notification.scss";

import InfoIcon from "../../assets/images/icons/info-icon.svg";
import SuccessIcon from "../../assets/images/icons/verified-icon.svg";
import WarningIcon from "../../assets/images/icons/pending-icon.svg";
import ErrorIcon from "../../assets/images/icons/not-verified-icon.svg";

function Notification(props) {
	return toast(
		<div className="flex-row">
			<img
				className="toast-icon"
				src={
					props.type === "info"
						? InfoIcon
						: props.type === "success"
						? SuccessIcon
						: props.type === "warning"
						? WarningIcon
						: ErrorIcon
				}
				alt=""
			/>
			<span className="s7-5">{props.text}</span>
		</div>,
		{
			type: props.type,
			hideProgressBar: true,
			autoClose: 5000,
		}
	);
}

export default Notification;
