import { useRef, forwardRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "../../styles/components/recaptcha.scss";

const Recaptcha = forwardRef((props, ref) => {
	const recaptchaRef = useRef(null);
	const recaptchaKey = window.location.origin.match(/localhost/g)
		? "6LelJJwjAAAAABuhLH6tSkyeyxFl-VDa47g_bL-l"
		: process.env.REACT_APP_RECAPTCHA_KEY;

	const recaptchaVerify = (response) => {
		if (response) {
			props.onChange({
				valid: true,
				token: response,
			});
		}
	};
	const recaptchaExpired = () => {
		props.onChange({
			valid: false,
			token: null,
		});
	};

	return (
		<ReCAPTCHA
			id="recaptcha"
			ref={ref || recaptchaRef}
			sitekey={recaptchaKey}
			onChange={recaptchaVerify}
			onExpired={recaptchaExpired}
			style={{ ...props.styles }}
		/>
	);
});

export default Recaptcha;
