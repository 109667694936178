import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "./externalAccountItems.scss";
import { withLocalize } from "react-localize-redux";
import { useAppDispatch } from "../../store/hooks";
import Notification from "../notification/Notification";
import translations from "../../translations/externalSSO.json";
import { userActions } from "../../store/features/user";
import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import mixpanel from "mixpanel-browser";
import { OptimizedMixPanel } from "wallet-elementkit/dist/services";
import { setToken } from "../../backend/StorageUtils";

const ExternalAccountItems = (props) => {
	useLayoutEffect(() => {
		props.addTranslation(translations);
	}, []);
	const optimizedMixpanel = new OptimizedMixPanel(mixpanel);
	const externalAuthIFrameRef = useRef(null);
	const centralAuthOrigin = process.env.REACT_APP_EXTERNAL_AUTH_IFRAME_ORIGIN;
	const dispatch = useAppDispatch();
	const history = useHistory();

	const onSuccess = (userResponse, type) => {
		dispatch(userActions.setUser(userResponse.user));
		dispatch(userActions.setUserWalletData(userResponse.userWalletData));
		dispatch(userActions.setUserEmails(userResponse.userEmails));
		dispatch(userActions.setLogged(true));
		dispatch(userActions.setIsMailConfirmed(true));
		dispatch(userActions.setAllUserWallets(userResponse.allUserWallets));
		mixpanel.identify(userResponse.user.id.toString());
		mixpanel.people.set({
			$email: userResponse.user.email,
			$name: userResponse.user.name,
			username: userResponse.user.username,
			emails: userResponse.userEmails.map((email) => email.value),
		});
		optimizedMixpanel.track("External Authentication", {
			type,
		});
		if (!history.location.pathname.includes("verify"))
			history.push({
				pathname: `/${
					props.activeLanguage ? props.activeLanguage.code : "en"
				}/profile/${userResponse.user.username}`,
			});
	};

	const onFail = (error) => {
		console.log("onFail", error);
		Notification({
			type: "error",
			text: props.translate(error).toString(),
		});
	};

	// Iframe ref on load send "ping" message
	useEffect(() => {
		const iframe = externalAuthIFrameRef.current;
		if (iframe) {
			iframe.onload = () => {
				iframe.contentWindow.postMessage("ping", centralAuthOrigin);
			};
		}
	}, [externalAuthIFrameRef]);

	// Listen to the message from central auth
	const receiveMessage = useCallback((event) => {
		if (
			event.origin === centralAuthOrigin &&
			event.data?.from === "wallet-external-auth"
		) {
			if (event.data.userResponse && !event.data.error) {
				setToken(event.data.userResponse.token);
				onSuccess(event.data.userResponse, event.data.type);
			} else {
				onFail(event.data.error);
			}
		}
	}, []);

	useEffect(() => {
		window.addEventListener("message", receiveMessage, false);
		return () => {
			window.removeEventListener("message", receiveMessage, false);
		};
	}, []);

	return (
		<iframe
			ref={externalAuthIFrameRef}
			src={process.env.REACT_APP_EXTERNAL_AUTH_IFRAME_LINK}
			style={{ border: "none" }}
			height={40}
			scrolling="no"
			title="external-account-items"
		></iframe>
	);
};

export default withLocalize(ExternalAccountItems);
