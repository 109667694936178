export const errorValueConverter = (error) => {
	if (
		error === "Please verify your email using the link." ||
		error === "Email already exists." ||
		error === "user already exists with that email"
	) {
		return "emailAlreadyInUse";
	} else {
		return "error";
	}
};
