import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import { Switch } from "react-router-dom";
import { langs } from "../../translations/translateJsonFile";
let { certificateLanguages, certificateLanguageCodes } = langs;

let activeLang = "";

class SwitchLocalizeWrapper extends React.Component {
	constructor(props) {
		super(props);
		this.props.initialize({
			languages: certificateLanguages,
			options: { renderToStaticMarkup },
		});

		let curr = this.getLocation(window.location.href);
		let cont = curr.pathname.split("/");

		if (cont.length > 1 && certificateLanguageCodes.includes(cont[1])) {
			activeLang = cont[1];
			this.props.setActiveLanguage(cont[1]);
		} else {
			let mainLang = this.getLocale();
			let langSelected = false;
			for (const langCode of certificateLanguageCodes) {
				if (mainLang.includes(langCode)) {
					mainLang = langCode;
					langSelected = true;
				}
			}
			if (langSelected !== true) mainLang = "en";

			window.location.replace(`${cont[0]}/${mainLang}${curr.pathname}`);
		}
	}

	getLocation = (href) => {
		var l = document.createElement("a");
		l.href = href;
		return l;
	};

	getLocale = () => {
		return navigator.languages && navigator.languages.length
			? navigator.languages[0]
			: navigator.language;
	};

	render() {
		const childrenWithProps = React.Children.map(
			this.props.children,
			(child) => {
				if (child) {
					if (child.props.path) {
						return React.cloneElement(child, {
							path: `/${activeLang}${child.props.path}`,
						});
					} else {
						return React.cloneElement(child); // For 404
					}
				}
			}
		);

		return <Switch>{childrenWithProps}</Switch>;
	}
}

export default withLocalize(SwitchLocalizeWrapper);
