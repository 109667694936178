const defaultLanguages = [
	{ name: "English", code: "en" },
	{ name: "Türkçe", code: "tr" },
];

const certificateLanguages = [
	{ name: "中文", code: "zh" },
	{ name: "हिंदी", code: "hi" },
	{ name: "Español", code: "es" },
	{ name: "Français", code: "fr" },
	{ name: "عربى", code: "ar" },
	{ name: "বাংলা", code: "bn" },
	{ name: "русский", code: "ru" },
	{ name: "Português", code: "pt" },
	{ name: "bahasa Indonesia", code: "id" },
	{ name: "اردو", code: "ur" },
	{ name: "Deutsche", code: "de" },
	{ name: "日本人", code: "ja" },
	{ name: "සිංහල", code: "si" },
	{ name: "Magyar", code: "hu" },
	{ name: "Polskie", code: "pl" },
	{ name: "Italiano", code: "it" },
];

var allCertificateLanguages = defaultLanguages.concat(
	certificateLanguages.sort((a, b) => a.name.localeCompare(b.name))
);

const allCertificateLanguageCodes = allCertificateLanguages.map(
	(lang) => lang.code
);

module.exports.langs = Object.freeze({
	certificateLanguages: allCertificateLanguages,
	certificateLanguageCodes: allCertificateLanguageCodes,
});
