import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { store } from "./store/store";
import { setElementkitConfig } from "wallet-elementkit/dist/config/index";

const config = {
	main_address: process.env.REACT_APP_MAIN_ADDRESS,
	twitterShareVia: process.env.REACT_APP_CREDENTIAL_SHARE_TWITTER_VIA,
	twitterShareViaTr: process.env.REACT_APP_CREDENTIAL_SHARE_TWITTER_VIA_TR,
	twitterShareRelatedTags:
		process.env.REACT_APP_TWITTER_SHARE_RELATED_TAGS.split(" "),
	facebookShareVia: process.env.REACT_APP_CREDENTIAL_SHARE_FACEBOOK_VIA,
	facebookShareViaTr: process.env.REACT_APP_CREDENTIAL_SHARE_FACEBOOK_VIA_TR,
	linkedinShareVia: process.env.REACT_APP_CREDENTIAL_SHARE_LINKEDIN_VIA,
	commonShareVia: process.env.REACT_APP_CREDENTIAL_SHARE_COMMON_VIA,
	subDomain: process.env.REACT_APP_SUB_DOMAIN,
	logoLink: process.env.REACT_APP_LOGO_LINK,
	logoRedirectUrl: process.env.REACT_APP_LOGO_REDIRECT_URL,
	googleAuthClientKey: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_KEY,
	jsonOpenBadgesRedirectLink:
		process.env.REACT_APP_JSON_OPEN_BADGES_REDIRECT_LINK,
	jsonSpecificationRedirectLink:
		process.env.REACT_APP_JSON_SPECIFICATION_REDIRECT_LINK,
	baseMailTo: process.env.REACT_APP_BASE_MAIL_TO,
	helpPopupQuestionAboutSharingRedirectLink:
		process.env.REACT_APP_HELP_POPUP_QUESTION_ABOUT_SHARING_REDIRECT_LINK,
	helpPopupNeedHelpWithMyCredentialRedirectLink:
		process.env.REACT_APP_HELP_POPUP_NEED_HELP_WITH_MY_CREDENTIAL_REDIRECT_LINK,
	primaryColor: process.env.REACT_APP_PRIMARY_COLOR,
	primaryGreenColor: process.env.REACT_APP_PRIMARY_GREEN,
	recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
	isWhitelabel: true,
};

setElementkitConfig(config);

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
