import { getToken } from "../../backend/StorageUtils";
import { emailVerificationRoutes } from "../../constants/Routes";
import { getUserInfo } from "./user";

const axios = require("axios");

export const verifyEmail = async (code) => {
	try {
		const token = getToken() || "";
		const res = await axios.post(
			emailVerificationRoutes.verifyEmail,
			{ code },
			{
				headers: {
					Authorization: token,
				},
			}
		);
		const responseJson = res.data;
		if (!responseJson.success) throw new Error();

		const userInfo = await getUserInfo(token);
		return userInfo;
	} catch (error) {
		return error.response.data;
	}
};

export const resendEmailVerificationCode = async (email, recaptchaToken) => {
	try {
		const token = getToken() || "";
		const res = await axios.post(
			emailVerificationRoutes.resendEmailVerification,
			{ reCaptchaToken: recaptchaToken, email },
			{
				headers: {
					Authorization: token,
				},
			}
		);
		const responseJson = res.data;
		if (!responseJson.success) throw new Error();

		return responseJson;
	} catch (error) {
		return error.response.data;
	}
};
