import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/user";

export const store = configureStore({
	reducer: {
		user: userReducer,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export var RootState = store.getState();

export var AppDispatch = store.dispatch;

export var DispatchActionTypes = {
	// User
	SET_USER: 1,
};
